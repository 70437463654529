<template>
  <v-card
    dark
    class="lighten--4"
    :color="primaryColor"
    max-width="400px"
  >
    <v-card-title
      class="text-break"
    >
      {{ coupon.label }}
    </v-card-title>
    <v-card-subtitle
      v-if="coupon.customer && coupon.customer.address && coupon.customer.address.name_line"
      :class="coupon.item ? 'pb-1': ''"
    >
      {{ $t('name') }}: <strong>{{ coupon.customer.address.name_line }}</strong>
    </v-card-subtitle>
    <v-card-subtitle v-else>
      {{ coupon.description }}
    </v-card-subtitle>
    <v-card-subtitle
      class="pt-0"
      v-if="coupon.customer && coupon.item && coupon.item.title"
    >
      {{ $t('item') }} <span class="font-weight-bold">{{ coupon.item.title }}</span>
    </v-card-subtitle>
    <v-card-text
      class="pb-0"
      style="font-size: 16px;"
    >
      {{ $t('code') }}: <strong>{{ coupon.code }}</strong>
    </v-card-text>
    <v-card-text class="pt-0">
      {{ $t('percentOff') }}: <strong>{{ coupon.percentOff }}</strong>
    </v-card-text>
    <v-card-text class="py-1">
      <v-container class="pa-0">
        <v-row dense>
          <v-col>
            <div>
              {{ $t('timesUsed') }}
            </div>
            <div class="font-weight-bold">
              {{ coupon.timesRedeemed }}
            </div>
          </v-col>
          <v-col>
            <div>{{ $t('maxAllowedUses') }}:</div>
            <div
              class="font-weight-bold"
              v-if="coupon.maxRedemptions > 0"
            >
              {{ coupon.maxRedemptions }}
            </div>
            <div
              class="font-weight-bold"
              v-else
            >
              {{ $t('unlimited') }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-text
      v-if="hasTimeSettings"
      class="py-1"
    >
      <v-row dense>
        <v-col
          cols="12"
          md="6"
          v-if="coupon.effectiveStartingAtTimestampInMillis"
        >
          <div>
            {{ $t('activeFrom') }}:
          </div>
          <div class="font-weight-bold">
            {{ timeStringToDate(coupon.effectiveStartingAtTimestampInMillis) }}
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          v-if="coupon.effectiveEndingAtTimestampInMillis"
        >
          <div>
            {{ $t('activeUntil') }}:
          </div>
          <div class="font-weight-bold">
            {{ timeStringToDate(coupon.effectiveEndingAtTimestampInMillis) }}
          </div>
        </v-col>
        <v-col
          cols="12"
          v-if="hasActiveWeekdays"
        >
          <div class="pb-1">
            {{ $t('activeOnWeekdays') }}:
          </div>
          <template v-for="(weekday, index) in coupon.validWeekdays">
            <v-chip
              :key="index"
              small
              class="mr-2 mb-1"
            >
              {{ weekdayText(weekday) }}
            </v-chip>
          </template>
        </v-col>
        <v-col
          cols="12"
          v-if="hasValidHours"
        >
          <div class="pb-1">
            <span v-if="hasActiveWeekdays">{{ $t('betweenHours') }}:</span>
            <span v-else>{{ $t('activeBetweenHours') }}:</span>
          </div>
          <template v-for="(hour, hourIndex) in coupon.validHours">
            <div :key="`hour-${hourIndex}`">
              <v-chip
                small
                v-if="hour"
              >
                {{ `${hour.startTime} - ${hour.endTime}` }}
              </v-chip>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pb-0">
      <v-container
        fluid
        class="pa-0"
      >
        <v-row
          dense
          justify="start"
        >
          <v-col cols="auto">
            <v-btn
              outlined
              small
              @click="viewCouponRedemptions"
            >
              {{ $t('viewRedemptions') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog
        persistent
        v-if="showRedemptionsDialog"
        v-model="showRedemptionsDialog"
        max-width="800"
        scrollable
      >
        <CouponRedemptions
          :coupon="coupon"
          @close="showRedemptionsDialog = false"
        />
      </v-dialog>
    </v-card-actions>
    <v-card-actions>
      <v-btn
        target="_blank"
        :href="'https://coupons.salescloud.is?uuid=' + coupon.uuid + '&organization=' + organization.uuid"
        small
        outlined
      >
        {{ $t('viewOnline') }}
        <v-icon right>
          mdi-open-in-new
        </v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        small
        outlined
        :to="'/store/coupons/' + coupon.uuid"
      >
        {{ $t('view') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CouponRedemptions from "@/components/coupon/CouponRedemptions"

export default {
  name: 'CouponCard',
  components: {
    CouponRedemptions
  },
  props: {
    coupon: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showRedemptionsDialog: false
    }
  },
	computed: {
		organization() {
			return this.$store.state.organization
		},
		primaryColor() {
			return this.$store.getters.primaryColor
		},
		weekdays() {
			return this.$store.getters.weekdays
		},
		hasActiveWeekdays() {
			return !!(this.coupon.validWeekdays && this.coupon.validWeekdays.length)
		},
		hasTimeSettings() {
			return !!(this.coupon.effectiveStartingAtTimestampInMillis || this.coupon.effectiveEndingAtTimestampInMillis || this.hasActiveWeekdays)
		},
    hasValidHours() {
      if(typeof this.coupon !== 'undefined' && this.coupon !== null) {
        return Array.isArray(this.coupon.validHours) && this.coupon.validHours.length > 0
      }
      return false
    }
	},
	methods: {
		weekdayText(weekday) {
			return this.weekdays.filter(w => w.value === weekday)[0].text
		},
    viewCouponRedemptions() {
      this.showRedemptionsDialog = !this.showRedemptionsDialog
    },
		timeStringToDate(timeString) {
			const date = new Date(timeString)
			return date.toISOString().substr(0, 10) + ' ' + date.toISOString().substr(11, 5)
		}
	}
}
</script>
